import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Logo from "../components/Logo"

import "../styles/Store.css"

const StorePage = () => {
	return (
		<>
			<Helmet>
				<title>Google Feud - Store</title>
			</Helmet>
			<Logo />
			<div
				id="StoreWrapper"
				style={{
					marginTop: "var(--single)",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}>
				<div className="StoreItem">
					<OutboundLink target="_blank" href="https://amzn.to/2SJJTaK">
						<StaticImage
							loading="eager"
							objectFit="scale-down"
							className="StoreImage"
							alt="Autocomplete: The Game"
							src="../images/autocomplete.gif"
						/>
					</OutboundLink>
					<h2>
						<OutboundLink target="_blank" href="https://amzn.to/2SJJTaK">
							Autocomplete: The&nbsp;Game
						</OutboundLink>
					</h2>
					<p>Bring home Google Feud in a box.</p>
				</div>

				<div className="StoreItem" style={{ flex: 1.25 }}>
					<OutboundLink target="_blank" href="https://amzn.to/2LIeSn4">
						<StaticImage
							loading="eager"
							objectFit="scale-down"
							className="StoreImage"
							alt="Autocomplete: The Book"
							src="../images/book.jpg"
						/>
					</OutboundLink>
					<h2>
						<OutboundLink target="_blank" href="https://amzn.to/2LIeSn4">
							Autocomplete: The&nbsp;Book
						</OutboundLink>
					</h2>
					<p>
						Each page contains one search and its 10 best autocomplete
						suggestions, from "Why is Ryan Gosling...eating cereal?" to "If the
						Earth is round...why are shoes flat?" With easy-to-read spreads and
						playful black-and-white line art throughout.
					</p>
				</div>

				<div className="StoreItem">
					<OutboundLink
						target="_blank"
						href="https://googlefeud.creator-spring.com">
						<StaticImage
							loading="eager"
							objectFit="scale-down"
							alt="Google Feud Mug"
							className="StoreImage"
							src="../images/mug.png"
						/>
					</OutboundLink>
					<h2>
						<OutboundLink
							target="_blank"
							href="https://googlefeud.creator-spring.com">
							Merch
						</OutboundLink>
					</h2>
					<p>Google Feud mugs, t-shirts, and stickers from TeeSpring.</p>
				</div>
			</div>
		</>
	)
}
export default StorePage
